<template>
  <base-page
    :title="$t('terminalLog.title')"
    :formData="formData"
    :config="config"
    @functionMethod="functionMethod"
    @search="search"
  >
    <table-list
      ref="tableList"
      :url="'/' + $util.getLocalStorage('apiprefix') + $api.clientLogFind"
      :columns="columns"
      :defaultParams="defaultParams"
      v-model="tableRow"
    >
      <!-- 创建时间 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime, 'yyyy-MM-dd hh:mm:ss') }}
      </template>
      <!-- 文件 -->
      <template v-slot:file="{ row }">
        <template v-if="row.file">
          <el-link
            type="primary"
            :href="row.file.fileHost + '/' + row.file.objectName"
            :download="row.file.originalName"
            target="_blank"
          >
            {{ row.file && row.file.originalName }}
          </el-link>
        </template>
      </template>
    </table-list>
  </base-page>
</template>

<script>
export default {
  data() {
    const nowTime = new Date().getTime()
    const prevTime = nowTime - 365 * 24 * 60 * 60 * 1000
    const startCreateTime = this.$util.format(prevTime, 'yyyy-MM-dd')
    const endCreateTime = this.$util.format(nowTime, 'yyyy-MM-dd')
    return {
      defaultParams: {
        startCreateTime: new Date(startCreateTime + ' 00:00:00').getTime(),
        endCreateTime: new Date(endCreateTime + ' 23:59:59').getTime(),
      },
      // 搜索
      formData: {
        createTime: [startCreateTime, endCreateTime],
      },
      config: [
        // lang:类型
        // {
        //   tag: 'el-select',
        //   label: this.$t('terminalLog.type'),
        //   span: 8,
        //   prop: 'type',
        //   tagProps: {
        //     options: [
        //       {
        //         value: 'Android',
        //         label: 'Android',
        //       },
        //       {
        //         value: 'PC',
        //         label: 'PC',
        //       },
        //     ],
        //   },
        // },
        {
          tag: 'el-date-picker',
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 10,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: this.$t('base.startDate'),
            endPlaceholder: this.$t('base.endDate'),
          },
        },
      ],
      // 表格页
      tableRow: null,
      yesOrNoObj: this.$util.listToObj(this.$dict.yesOrNo()),
      columns: [
        // lang:创建时间
        {
          label: this.$t('terminalLog.createTime'),
          prop: 'createTime',
        },
        // lang:类型
        {
          label: this.$t('terminalLog.type'),
          prop: 'type',
          callback(row) {
            return row.type && row.type.value
          },
        },
        // lang:所属企业
        {
          label: this.$t('terminalLog.company'),
          prop: 'company',
          callback(row) {
            return row.company && row.company.name
          },
        },
        // lang:所属账号
        {
          label: this.$t('terminalLog.userAccount'),
          prop: 'userAccount',
          callback(row) {
            return row.userAccount && row.userAccount.accountId
          },
        },
        // lang:设备信息
        {
          label: this.$t('terminalLog.device'),
          prop: 'device',
        },
        // lang:日志文件
        {
          label: this.$t('terminalLog.file'),
          prop: 'file',
        },
      ],
    }
  },
  methods: {
    // 查询按钮点击回调
    search(isActiveQuery) {
      this.$refs.tableList.searchList(this.formData, isActiveQuery)
    },
    // 权限按钮方法回掉
    functionMethod() {},
  },
}
</script>